import * as React from "react";
import {
  Avatar,
  createStyles,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import HeaderMenu from "./HeaderMenu";
import { UserProfile } from "../../auth/UserContext";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import cx from "classnames";
import defaultAvatar from "../../../images/default-avatar.png";
import { FundName } from "../../../graphql/FundName";

const query = gql`
  query FundName {
    fund {
      id
      name
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      width: 30,
      height: 30
    },
    headerIcon: {
      color: "#D5D5D5"
    },
    profile: {
      display: "flex",
      alignItems: "center",
      opacity: 0,
      transition: "opacity 0.25s ease-in"
    },
    fadeIn: {
      opacity: 1
    },
    userinfo: {
      paddingLeft: 12,
      paddingRight: 6
    },
    username: {
      color: "#555757",
      fontSize: 14,
      fontWeight: "bold"
    },
    fundname: {
      color: "#939DA3",
      fontSize: 11
    },
    badge: {
      minWidth: 15,
      width: 15,
      height: 15,
      fontSize: 10
    }
  });

interface Props extends WithStyles<typeof styles> {
  profile: UserProfile;
}

class HeaderProfile extends React.Component<Props> {
  render() {
    const { profile, classes } = this.props;
    return (
      <Query<FundName> query={query}>
        {({ data, loading }) => (
          <div className={cx(classes.profile, !loading && classes.fadeIn)}>
            <Avatar src={defaultAvatar} className={classes.avatar} />
            <div className={classes.userinfo}>
              <Typography className={classes.username}>
                {profile.name}
              </Typography>
              {data && data.fund ? (
                <Typography className={classes.fundname}>
                  {data.fund.name}
                </Typography>
              ) : null}
            </div>
            <HeaderMenu />
          </div>
        )}
      </Query>
    );
  }
}

export default withStyles(styles)(HeaderProfile);
