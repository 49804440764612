import React, { ReactNode } from "react";
import { WithAuth, withAuth } from "./withAuth";
import { AuthHandler } from "./SecurityContext";

interface Props extends WithAuth {}

let auth: AuthHandler;

class HttpAuthHandler extends React.Component<Props> {
  componentDidMount() {
    auth = this.props.auth;
  }

  componentDidUpdate() {
    auth = this.props.auth;
  }

  render(): ReactNode {
    return null;
  }
}

export const isAuthenticated = async (): Promise<boolean> => {
  return auth ? auth.isAuthenticated() : false;
};
export const getToken = async (): Promise<string | undefined> =>
  auth.getIdToken();
export const getProfile = async (): Promise<any> => auth.getUser();
export const logout = (path?: string) =>
  auth.logout(path ? path : window.location.pathname);

export default withAuth(HttpAuthHandler);
