exports.wrapPageElement = require(`./src/layouts/layouts.tsx`).default;
const {
  startLoadingPage,
  finishLoadingPage
} = require("./src/components/common/PageProgressBar.tsx");

exports.onPreRouteUpdate = () => {
  startLoadingPage();
};

exports.onRouteUpdate = () => {
  finishLoadingPage();
};
