import React, { useState, useContext, useEffect } from "react";
import { WithDataset } from "./withDataset";
import { UserContext, UserProfile } from "./UserContext";

const LOCAL_STORAGE_DATASET_KEY = "sandboxDataset";
const deriveStorageKey = (sub: string) => `${LOCAL_STORAGE_DATASET_KEY}-${sub}`;
export const getLocalSandboxDataset = (profile: UserProfile) => {
  if (!profile || typeof localStorage === "undefined") {
    return true;
  }
  const storageKey = deriveStorageKey(profile.sub);
  return localStorage.getItem(storageKey) !== "false";
};
const persistDatasetState = (profile: UserProfile, state: boolean) => {
  if (!profile) {
    return;
  }
  const storageKey = deriveStorageKey(profile.sub);
  localStorage.setItem(storageKey, `${state}`);
};

export const DatasetContext = React.createContext<WithDataset>({
  sandboxDataset: true,
  switchDataset: () => {}
});

export const DatasetProvider: React.FC = ({ children }) => {
  const { profile } = useContext(UserContext);
  const [sandboxDataset, setSandboxDataset] = useState(
    getLocalSandboxDataset(profile)
  );

  const switchDataset = () => {
    persistDatasetState(profile, !sandboxDataset);
    setSandboxDataset(!sandboxDataset);
  };

  useEffect(() => {
    setSandboxDataset(getLocalSandboxDataset(profile));
  }, [profile]);

  return (
    <DatasetContext.Provider value={{ sandboxDataset, switchDataset }}>
      {children}
    </DatasetContext.Provider>
  );
};
