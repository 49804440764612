import React, { Component } from "react";
import {
  createStyles,
  Switch,
  WithStyles,
  withStyles
} from "@material-ui/core";
import gql from "graphql-tag";
import { WithDataset, withDataset } from "../../auth/withDataset";
import { Mutation } from "react-apollo";
import {
  CanSwitchDataset,
  CanSwitchDatasetVariables
} from "../../../graphql/CanSwitchDataset";
import { MY_APPS_QUERY } from "../../apps/ListApps";
import {
  FUND_BALANCE_QUERY,
  FUND_BALANCE_VARIABLES
} from "../../dashboard/Charts";
import { showMessage } from "../Toast";
import { orange } from "@material-ui/core/colors";
import { WEBHOOKS_QUERY } from "../../webhook/ListWebhook";

const styles = () =>
  createStyles({
    track: {
      backgroundColor: orange[500]
    }
  });

const mutation = gql`
  mutation CanSwitchDataset($name: String!) {
    canSwitchDataset(name: $name)
  }
`;

interface Props extends WithDataset, WithStyles<typeof styles> {}

class DatasetSwitcher extends Component<Props> {
  render() {
    const { classes, sandboxDataset, switchDataset } = this.props;

    const onComplete = (data: any) => {
      if (data && data.canSwitchDataset) {
        switchDataset();
      } else {
        showMessage("Dataset not found", true);
      }
    };

    return (
      <Mutation<CanSwitchDataset, CanSwitchDatasetVariables>
        mutation={mutation}
        onCompleted={onComplete}
      >
        {canSwitchDataset => (
          <Switch
            color="secondary"
            checked={!!sandboxDataset}
            value="sandboxDataset"
            onClick={async e => {
              e.stopPropagation();

              const switchTo = sandboxDataset ? "live" : "sandbox";
              await canSwitchDataset({
                variables: { name: switchTo }
              });
            }}
            title={`${sandboxDataset ? "Sandbox" : "Live"} data active`}
            classes={{
              track: classes.track
            }}
          />
        )}
      </Mutation>
    );
  }
}

export default withDataset(withStyles(styles)(DatasetSwitcher));
