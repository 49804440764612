// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apply-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/apply.tsx" /* webpackChunkName: "component---src-pages-apply-tsx" */),
  "component---src-pages-apps-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-code-samples-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/code-samples.tsx" /* webpackChunkName: "component---src-pages-code-samples-tsx" */),
  "component---src-pages-first-app-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/first-app.tsx" /* webpackChunkName: "component---src-pages-first-app-tsx" */),
  "component---src-pages-first-steps-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/first-steps.tsx" /* webpackChunkName: "component---src-pages-first-steps-tsx" */),
  "component---src-pages-get-started-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-implicit-callback-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/implicit/callback.tsx" /* webpackChunkName: "component---src-pages-implicit-callback-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-register-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-sign-in-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/sign-in.tsx" /* webpackChunkName: "component---src-pages-sign-in-tsx" */),
  "component---src-pages-webhooks-tsx": () => import("/opt/atlassian/pipelines/agent/build/packages/portal-web/src/pages/webhooks.tsx" /* webpackChunkName: "component---src-pages-webhooks-tsx" */)
}

