import * as React from "react";
import ProgressBar from "./ProgressBar";

interface State {
  loading: number;
  pageLoading: boolean;
  visible: boolean;
}

let loading = 0;
let start = () => {
  /* Do nothing */
};
let finish = () => {
  /* Do nothing */
};

let startPage = () => {
  /* Do nothing */
};
let finishPage = () => {
  /* Do nothing */
};

export class DelayLoading extends React.PureComponent<{}, State> {
  public constructor(props: {}) {
    super(props);

    this.state = {
      loading: 0,
      visible: false,
      pageLoading: false
    };

    start = () => {
      loading += 1;

      this.setState({
        loading
      });
    };

    finish = () => {
      loading = Math.max(loading - 1, 0);

      this.setState({
        loading
      });
    };

    startPage = () => this.setState({ pageLoading: true });
    finishPage = () => this.setState({ pageLoading: false });
  }

  public render() {
    return <ProgressBar isLoading={this.state.loading > 0} />;
  }
}

export const startLoading = () => {
  start();
};

export const finishLoading = () => {
  finish();
};

export const startLoadingPage = () => {
  startPage();
};

export const finishLoadingPage = () => {
  finishPage();
};
