module.exports = [{
      plugin: require('/opt/atlassian/pipelines/agent/build/packages/portal-web/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/packages/portal-web/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/atlassian/pipelines/agent/build/packages/portal-web/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
