import {
  Theme,
  WithStyles,
  withStyles,
  LinearProgress,
  createStyles,
  Fade
} from "@material-ui/core";
import * as PropTypes from "prop-types";
import * as React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "relative",
      zIndex: 5000,
      height: 2,
      marginBottom: -2
    }
  });

interface Props extends WithStyles<typeof styles> {
  isLoading?: boolean;
}

class Progress extends React.PureComponent<Props> {
  public static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired
  };

  public render() {
    return (
      <Fade in={this.props.isLoading} unmountOnExit timeout={500}>
        <LinearProgress className={this.props.classes.root} />
      </Fade>
    );
  }
}

export default withStyles(styles)(Progress);
