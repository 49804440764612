import React, { Component } from "react";
import {
  Icon as MaterialIcon,
  withStyles,
  WithStyles
} from "@material-ui/core";
import cx from "classnames";

interface Props extends WithStyles<typeof styles> {
  icon: string;
  className?: string;
}

const styles = {
  root: {}
};

class Icon extends Component<Props> {
  render() {
    return (
      <MaterialIcon
        className={cx(
          "icon",
          `icon-${this.props.icon}`,
          this.props.classes.root,
          this.props.className
        )}
      />
    );
  }
}

export default withStyles(styles)(Icon);
