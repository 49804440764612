import { createMuiTheme } from "@material-ui/core";

export const colors = {
  header: "#070B0D",
  headerHover: "#1f2125",
  headerSecondary: "#171B1D",
  headerContrast: "#b7bbbD",
  primary: "#00777B",
  primaryDark: "#0C4551",
  primaryLight: "#05A3A9",
  secondary: "#05C0C7",
  tertiary: "#7FE5E9",
  white: "#ffffff",
  lime: "#05C0C7",
  background: "#F4F6F8",
  orange: "#FA613B",
  blueGrey: "#939DA3",
  darkGrey: "#4A4A4A"
};

export const gradients = {
  header: colors.primary
};

export const contentFamily = 'muli, "Helvetica", "Arial", sans-serif';
export const titleFamily = 'saira, "Helvetica", "Arial", sans-serif';

export const onboardingWidth = 900;
export const sidebarWidth = 275;

export default createMuiTheme({
  typography: {
    fontFamily: contentFamily,
    h6: {
      fontFamily: titleFamily
    },
    h5: {
      fontFamily: titleFamily
    },
    subtitle1: {
      fontFamily: titleFamily
    }
  },
  palette: {
    primary: {
      main: "#00777B",
      contrastText: "#ffffff"
    },
    secondary: {
      main: colors.orange
    },
    error: {
      main: "#F9883D"
    }
  },
  shape: {
    borderRadius: 5
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.white
      },
      root: {
        boxShadow: "none"
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: colors.header,
        color: colors.headerContrast,
        fontWeight: 800
      }
    },
    MuiButton: {
      root: {
        textTransform: "capitalize"
      },
      contained: {
        boxShadow: "none"
      }
    },
    MuiCard: {
      root: {
        boxShadow: "0 3px 6px rgba(100, 100, 100, 0.1)",
        transition: "all 0.2s",
        borderRadius: 10
      }
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: colors.orange,
        color: colors.white
      }
    }
  }
});
