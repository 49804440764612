import React from "react";
import Amplify, { Auth } from "aws-amplify";
import { globalHistory } from "@reach/router/lib/history";
import { navigate } from "gatsby-link";
import { CognitoUser } from "amazon-cognito-identity-js";
import awsConfig from "../../aws-exports";

Amplify.configure(awsConfig);

export interface AuthHandler {
  isAuthenticated(): Promise<boolean>;

  getIdToken(): Promise<string | undefined>;

  getAccessToken(): Promise<string | undefined>;

  logout(path: string, urlState?: any): Promise<void>;

  handleAuthentication(): Promise<any>;

  getAuthClient(): any;

  getUser(): any;
}

class CognitoAuthHandler implements AuthHandler {
  async getIdToken(): Promise<string | undefined> {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (err) {
      console.log("err", err);
      return null;
    }
  }

  async getAccessToken(): Promise<string | undefined> {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  }

  getAuthClient(): any {
    // TODO
  }

  async getUser(): Promise<any> {
    const user = await Auth.currentAuthenticatedUser();
    return user.attributes;
  }

  handleAuthentication(): Promise<any> {
    // TODO
    return undefined;
  }

  async isAuthenticated(): Promise<boolean> {
    try {
      await this.getUser();
      return true;
    } catch (e) {
      return false;
    }
  }

  async logout(path: string, urlState?: any): Promise<void> {
    await Auth.signOut();

    navigate(path, {
      replace: true,
      state: urlState
    });
  }
}

class FakeAuthHandler implements AuthHandler {
  async isAuthenticated() {
    return false;
  }

  async getIdToken(): Promise<string | undefined> {
    return undefined;
  }

  async getAccessToken(): Promise<string | undefined> {
    return undefined;
  }

  async handleAuthentication(): Promise<any> {
    return undefined;
  }

  async logout(path: string, urlState?: any): Promise<void> {}

  getAuthClient(): any {}

  async getUser(): Promise<any> {
    return {};
  }
}

export const isBrowser = (): boolean => typeof window !== "undefined";
export const SecurityContext = React.createContext({});

interface Props {}

export class SecurityProvider extends React.Component<Props> {
  private readonly auth: AuthHandler;

  constructor(props: Props) {
    super(props);
    this.auth = isBrowser() ? new CognitoAuthHandler() : new FakeAuthHandler();
  }

  render() {
    return (
      <SecurityContext.Provider value={this.auth}>
        {this.props.children}
      </SecurityContext.Provider>
    );
  }
}
