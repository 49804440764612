import { Theme, withStyles, WithStyles, createStyles } from "@material-ui/core";
import * as React from "react";
import withRoot from "../withRoot";

const styles = (theme: Theme) =>
  createStyles({
    page: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      justifyContent: "space-around"
    }
  });

interface Props extends WithStyles<typeof styles> {
  links?: JSX.Element;
  title: string;
}

const Loading: React.SFC<Props> = ({ classes, children }) => (
  <div className={classes.page}>{children}</div>
);

export default withRoot(withStyles(styles)(Loading));
