import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import { withClientState } from "apollo-link-state";
import { showMessage } from "../components/common/Toast";
import {
  getToken,
  logout,
  getProfile
} from "../components/auth/HttpAuthHandler";
import { defaults, resolvers, typeDefs } from "../resolvers/onboarding";
import {
  finishLoading,
  startLoading
} from "../components/common/PageProgressBar";
import { getLocalSandboxDataset } from "../components/auth/DatasetContext";

const authFetch = async (path: string, options: RequestInit) => {
  startLoading();
  try {
    const token = await getToken();
    const profile = await getProfile();
    const dataset = getLocalSandboxDataset(profile) ? "sandbox" : "live";

    const result = await fetch(path, {
      ...options,
      headers: {
        ...options.headers,
        authorization: `Bearer ${token}`,
        "X-Dataset": dataset
      }
    });

    return result;
  } finally {
    finishLoading();
  }
};

const client = new ApolloClient({
  defaultOptions: {
    query: {
      fetchPolicy: "cache-and-network"
    },
    watchQuery: {
      fetchPolicy: "cache-and-network"
    }
  },
  link: ApolloLink.from([
    withClientState({
      resolvers,
      defaults,
      typeDefs: [typeDefs]
    }),
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          if (process.env.NODE_ENV !== "production") {
            showMessage(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
              true
            );
          } else {
            // tslint:disable:no-console
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
          }
        });
      }
      if (networkError) {
        const statusCode = (networkError as any).statusCode;
        if (statusCode === 401) {
          logout("/sign-in")
            .then(() => console.log("Logged out"))
            .catch(() => console.log("Error logging out"));

          return;
        }

        if (process.env.NODE_ENV !== "production") {
          console.error(`[Network error]`, networkError);
          showMessage(`[Network error]: ${networkError.message}`, true);
        } else {
          console.error(`[Network error]`, networkError);
          showMessage(`Unexpected error fetching data`, true);
        }
      }
    }),
    new HttpLink({
      uri: `${process.env.GATSBY_IMPACT_API_HOST}/graphql`,
      fetch: authFetch
    })
  ]),
  cache: new InMemoryCache({
    cacheRedirects: {
      Query: {}
    }
  })
});

export default client;
