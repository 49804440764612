import React from "react";
import { DatasetContext } from "./DatasetContext";

export interface WithDataset {
  sandboxDataset: boolean;
  switchDataset: () => void;
}

export const withDataset = <P extends object>(
  Component: React.ComponentType<P>
) => (props: any) => (
  <DatasetContext.Consumer>
    {({ sandboxDataset, switchDataset }: any) => {
      return (
        <Component
          {...props}
          sandboxDataset={sandboxDataset}
          switchDataset={switchDataset}
        />
      );
    }}
  </DatasetContext.Consumer>
);
