import React from "react";
import {
  createStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import cx from "classnames";
import logo from "../../../images/certane-logo.png";
import logoSmall from "../../../images/certane-logo-small.png";
import SidebarLink from "./SidebarLink";
import SidebarGroup from "./SidebarGroup";
import { isBrowser } from "../../auth/SecurityContext";
import { colors, sidebarWidth } from "../../../Theme";
import Icon from "../Icon";
import DatasetSwitcher from "./DatasetSwitcher";

// Helpers for persisting the state of the sidebar
const LOCAL_STORAGE_STATE_KEY = "sidebar.open";
const sidebarDefaultState = () =>
  isBrowser()
    ? localStorage.getItem(LOCAL_STORAGE_STATE_KEY) !== "false"
    : true;
const persistSidebarState = (state: boolean) => {
  if (isBrowser()) {
    localStorage.setItem(LOCAL_STORAGE_STATE_KEY, `${state}`);
  }
};

const styles = (theme: Theme) =>
  createStyles({
    linksWrapper: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column"
    },
    drawerPaper: {
      position: "sticky",
      whiteSpace: "nowrap",
      height: "100vh",
      width: sidebarWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9)
      }
    },
    drawerIconWrapper: {
      display: "flex",
      alignItems: "center",
      borderBottom: `solid thin ${theme.palette.grey[900]}`,
      paddingLeft: theme.spacing(2),
      flexShrink: 0,
      height: 64,
      "& img": {
        height: 32
      }
    },
    drawerListIcon: {
      color: "#fff"
    },
    expands: {
      flexGrow: 1
    },
    links: {
      paddingTop: 0,
      flexDirection: "column",
      flexShrink: 1,
      overflowY: "auto",
      overflowX: "hidden"
    },
    fixedLinks: {
      paddingBottom: 0
    },
    dataToggleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1)
    },
    colorSwitchBase: {
      color: colors.orange,
      "&$colorChecked": {
        color: colors.orange,
        "& + $colorBar": {
          backgroundColor: "#FEC5A5",
          opacity: 1,
          height: 12,
          marginTop: -6
        }
      }
    },
    colorBar: {},
    colorChecked: {},
    toggleTextOn: {
      fontSize: 11,
      textTransform: "uppercase",
      color: colors.orange
    },
    toggleTextOff: {
      fontSize: 11,
      textTransform: "uppercase",
      color: colors.white
    }
  });

interface Props extends WithStyles<typeof styles> {
  authenticated?: boolean;
}

interface State {
  open?: boolean;
}

class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { open: true };
  }

  componentDidMount() {
    this.setState({ open: sidebarDefaultState() });
  }

  toggleDrawer = () => {
    const open = !this.state.open;
    persistSidebarState(open);
    this.setState({ open });
  };

  render() {
    const { classes, authenticated } = this.props;
    const returnToUrl = authenticated ? "/" : "https://www.impactcrm.com.au";
    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: cx(
            classes.drawerPaper,
            !this.state.open && classes.drawerPaperClose
          )
        }}
        open={this.state.open}
      >
        <a className={classes.drawerIconWrapper} href={returnToUrl}>
          <img src={this.state.open ? logo : logoSmall} />
        </a>
        <List className={classes.fixedLinks}>
          <SidebarLink
            title="Dashboard"
            label="Dashboard"
            path="/"
            secure
            icon="home"
            authenticated={authenticated || false}
          />
        </List>
        <div className={classes.linksWrapper}>
          <List className={cx(classes.links)}>
            <SidebarGroup
              title="Developers"
              label="Developers"
              path="/developers"
              icon="developer"
              authenticated={authenticated || false}
            >
              <SidebarLink
                title="API Keys"
                label="API Keys"
                path="/apps"
                parentPath="/developers"
                icon="api-key"
                secure
                authenticated={authenticated || false}
              />

              <SidebarLink
                title="Code Samples"
                label="Code Samples"
                path="/code-samples"
                parentPath="/developers"
                icon="prototype"
                secure
                authenticated={authenticated || false}
              />

              <SidebarLink
                title="API Reference"
                label="API Reference"
                path="https://api-docs.impactcrm.com.au/"
                icon="api"
                authenticated={authenticated || false}
              />

              <SidebarLink
                title="Demo Implementation"
                label="Demo Implementation"
                path={process.env.GATSBY_REFERENCE_APP_URL}
                icon="activate"
                authenticated={authenticated || false}
              />

              <SidebarLink
                title="Webhooks"
                label="Webhooks"
                path="/webhooks"
                icon="webhooks"
                secure
                authenticated={authenticated || false}
              />
            </SidebarGroup>
          </List>

          {authenticated && (
            <div className={classes.dataToggleContainer}>
              {this.state.open && (
                <React.Fragment>
                  <Icon icon="test" />
                  &nbsp;
                  <Typography className={classes.toggleTextOff}>
                    Live Data
                  </Typography>
                </React.Fragment>
              )}
              <DatasetSwitcher />
              {this.state.open && (
                <Typography className={classes.toggleTextOn}>
                  Sandbox Data
                </Typography>
              )}
            </div>
          )}

          {this.state.open && <div className={classes.expands} />}

          <List>
            <ListItem button onClick={this.toggleDrawer}>
              <ListItemIcon className={classes.drawerListIcon}>
                {this.state.open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </ListItemIcon>
            </ListItem>
          </List>
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(Layout);
