import { MemoryStorage } from "@aws-amplify/core";

const getStorage = () => {
  try {
    sessionStorage.setItem("aws.amplify.test-ls", "1");
    sessionStorage.removeItem("aws.amplify.test-ls");
    return sessionStorage;
  } catch (exception) {
    console.log("Using in memory storage");
    return MemoryStorage;
  }
};

export default {
  Auth: {
    region: process.env.GATSBY_AMPLIFY_REGION,
    userPoolId: process.env.GATSBY_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: process.env.GATSBY_AMPLIFY_AUTHENTICATION_FLOW_TYPE,
    storage: getStorage()
  }
};
