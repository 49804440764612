import React from "react";
import { globalHistory } from "@reach/router/lib/history";
import { withAuth, WithAuth } from "./withAuth";

interface UserDetails {
  authenticated: boolean | null;
  profile: UserProfile;
}

export interface UserProfile {
  givenName: string;
  familyName: string;
  name: string;
  sub: string;
}

export const UserContext = React.createContext<UserDetails>({
  authenticated: null,
  profile: null
});

interface Props extends WithAuth {}
interface State extends UserDetails {}

class UserProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      authenticated: null,
      profile: null
    };
  }

  componentDidMount() {
    this.loadUser().catch(error => console.log("Error loading user", error));
  }

  async loadUser() {
    const user = await this.props.auth.getUser();
    if (user) {
      const profile = {
        givenName: user.given_name,
        familyName: user.family_name,
        name: `${user.given_name} ${user.family_name}`,
        sub: user.sub
      };
      this.setState({ profile, authenticated: true });
    } else {
      this.setState({ authenticated: false, profile: null });
    }
  }

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default withAuth(UserProvider);
