import * as React from "react";
import {
  AppBar,
  createStyles,
  Theme,
  Toolbar,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { LinkButton } from "../LinkButton";
import { UserContext } from "../../auth/UserContext";
import HeaderProfile from "./HeaderProfile";

const styles = (theme: Theme) =>
  createStyles({
    header: {
      flexGrow: 1
    },
    root: {
      height: 64,
      display: "flex",
      justifyContent: "center"
    }
  });

interface Props extends WithStyles<typeof styles> {}

interface State {}

class Header extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <AppBar position="absolute" className={classes.root}>
        <Toolbar variant="dense">
          <div className={classes.header}></div>
          <UserContext.Consumer>
            {user => (
              <React.Fragment>
                {!user.authenticated && (
                  <LinkButton to="/sign-in" variant="text" color="default">
                    Login
                  </LinkButton>
                )}
                {user.profile && <HeaderProfile profile={user.profile} />}
              </React.Fragment>
            )}
          </UserContext.Consumer>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(Header);
