import GatsbyLink from "gatsby-link";
import React from "react";
import {
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles,
  createStyles,
  WithStyles,
  Theme
} from "@material-ui/core";
import cx from "classnames";
import Icon from "../Icon";
import { colors } from "../../../Theme";
import ProdLozenge from "./ProdLozenge";

interface SidebarLinkProps extends WithStyles<typeof styles> {
  label: string;
  title: string;
  path?: string;
  parentPath?: string;
  href?: string;
  icon?: string;
  secure?: boolean;
  unavailable?: boolean;
  authenticated: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    activeLink: {
      color: colors.lime,
      borderLeft: `solid 2px ${colors.lime}`,

      "& $drawerListIcon": {
        color: colors.lime
      }
    },
    drawerListIcon: {
      color: "#fff"
    },
    label: {
      fontSize: 14
    },
    root: {
      "&:hover": {
        background: colors.headerHover
      }
    },
    unavailable: {
      "&:not(:hover) $label": {
        color: "rgba(203, 203, 203, 0.3)"
      },
      "&:not(:hover) $drawerListIcon": {
        color: "rgba(203, 203, 203, 0.3)"
      }
    },
    tooltipHeader: {
      paddingTop: theme.spacing(1) / 2
    },
    tooltipUnavailable: {
      background: colors.orange,
      marginLeft: -theme.spacing(1),
      marginRight: -theme.spacing(1),
      marginBottom: -theme.spacing(1),
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(1) / 2,
      paddingTop: theme.spacing(1) / 2,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius
    }
  });

const SidebarLink: React.SFC<SidebarLinkProps> = ({
  title,
  label,
  secure,
  icon,
  authenticated,
  path,
  unavailable,
  classes
}) => {
  if (secure && !authenticated) {
    return null;
  }

  const isActive = (currentClassNames: string, props: any) => {
    const { isCurrent, isPartiallyCurrent, href } = props;

    let active = false;
    if (href === "/") {
      active = isCurrent;
    } else if (href !== "/apply") {
      active = isPartiallyCurrent;
    }

    return active
      ? { className: cx(currentClassNames, classes.activeLink) }
      : null;
  };

  const Link = React.forwardRef((props, ref) => {
    // Use Gatsby Link for internal links, and <a> for others
    const internal = /^\/(?!\/)/.test(path);
    if (internal) {
      return (
        <GatsbyLink
          {...props}
          state={unavailable ? { unavailableFeature: true } : {}}
          to={path}
          getProps={linkProps => isActive(props.className, linkProps)}
        />
      );
    }
    return <a href={path} {...props} target="_blank" />;
  });

  const tooltipContents = unavailable ? (
    <div>
      <div className={classes.tooltipHeader}>{title}</div>
      <div className={classes.tooltipUnavailable}>
        Available to Impact customers
      </div>
    </div>
  ) : (
    title
  );

  return (
    <Tooltip title={tooltipContents} placement="right" enterDelay={500}>
      <div>
        <ListItem
          button
          component={Link}
          classes={{ root: classes.root }}
          className={cx(unavailable && classes.unavailable)}
        >
          {icon && (
            <ListItemIcon>
              <Icon icon={icon} className={classes.drawerListIcon} />
            </ListItemIcon>
          )}
          <ListItemText
            inset={!Icon}
            primary={label}
            primaryTypographyProps={{
              color: "inherit",
              className: classes.label
            }}
          />
          {unavailable && <ProdLozenge />}
        </ListItem>
      </div>
    </Tooltip>
  );
};

export default withStyles(styles)(SidebarLink);
