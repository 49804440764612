import { ApolloCache } from "apollo-cache";
import { isBrowser } from "../components/auth/SecurityContext";

const Type = "Onboarding";

const updateOnboarding = (cache: ApolloCache<any>, visible: boolean) => {
  const onboarding = {
    onboardingStepperVisible: visible,
    __typename: Type
  };

  cache.writeData({
    data: {
      onboarding
    }
  });

  localStorage.setItem("onboarding", JSON.stringify(onboarding));

  return onboarding;
};

export const resolvers = {
  Query: {},
  Mutation: {
    openOnboardingStepper: (
      obj: void,
      args: void,
      { cache }: { cache: ApolloCache<any> }
    ) => {
      return updateOnboarding(cache, true);
    },
    closeOnboardingStepper: (
      obj: void,
      args: void,
      { cache }: { cache: ApolloCache<any> }
    ) => {
      return updateOnboarding(cache, false);
    }
  }
};

const { onboardingStepperVisible = true } = isBrowser()
  ? JSON.parse(localStorage.getItem("onboarding") || "{}")
  : {};

export const defaults = {
  onboarding: {
    onboardingStepperVisible,
    __typename: Type
  }
};

export const typeDefs = `
  type Onboarding {
    onboardingStepperVisible: Boolean!
  }
  type Mutation {
    openOnboardingStepper: Onboarding!
    closeOnboardingStepper: Onboarding!
  }
  type Query {
    onboarding: Onboarding!
  }
`;
