import React, { Component } from "react";
import { createStyles, withStyles, WithStyles, Theme } from "@material-ui/core";
import { colors } from "../../../Theme";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: 24,
      textAlign: "center"
    },
    dot: {
      textTransform: "capitalize",
      background: colors.orange,
      boxShadow: `0 0 5px ${colors.orange}`,
      color: theme.palette.common.black,
      width: 8,
      height: 8,
      display: "inline-block",
      borderRadius: 8
    }
  });

interface Props extends WithStyles<typeof styles> {}

export class ProdLozenge extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <span className={classes.dot} />
      </div>
    );
  }
}

export default withStyles(styles)(ProdLozenge);
