import {
  Collapse,
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import React, { ReactElement } from "react";
import cx from "classnames";
import Icon from "../Icon";
import { colors } from "../../../Theme";

interface SidebarGroupProps extends WithStyles<typeof styles> {
  title: string;
  label: string;
  icon?: string;
  path?: string;
  secure?: boolean;
  authenticated: boolean;
  children: ReactElement<any>[];
}

const styles = (theme: Theme) =>
  createStyles({
    drawerListIcon: {
      color: theme.palette.common.white
    },
    expanded: {
      background: colors.headerSecondary,
      "&:hover": {
        background: colors.headerSecondary
      }
    },
    label: {
      fontSize: 14
    },
    root: {
      "&:hover": {
        background: colors.headerHover
      }
    },
    expander: {
      color: theme.palette.common.white
    }
  });

class SidebarGroup extends React.Component<
  SidebarGroupProps,
  { open: boolean }
> {
  constructor(props: SidebarGroupProps) {
    super(props);

    let openGroup = false;
    this.props.children.map(child => {
      if (openGroup) {
        return;
      }

      if (
        this.props.path &&
        child.props.parentPath &&
        this.props.path === child.props.parentPath
      ) {
        openGroup = true;
        return;
      }
    });

    this.state = {
      open: openGroup
    };
  }

  render() {
    const {
      label,
      children,
      secure,
      authenticated,
      classes,
      icon
    } = this.props;

    if (secure && !authenticated) {
      return null;
    }

    return (
      <React.Fragment>
        <ListItem
          button
          onClick={this.handleClick}
          className={cx(classes.root, this.state.open && classes.expanded)}
        >
          {icon && (
            <ListItemIcon>
              <Icon icon={icon} className={classes.drawerListIcon} />
            </ListItemIcon>
          )}
          <ListItemText
            primaryTypographyProps={{
              color: "inherit",
              className: classes.label
            }}
            primary={label}
          />
          {this.state.open ? (
            <ExpandLess className={classes.expander} />
          ) : (
            <ExpandMore className={classes.expander} />
          )}
        </ListItem>
        <Collapse
          in={this.state.open}
          timeout="auto"
          unmountOnExit
          className={classes.expanded}
        >
          <List component="ul" disablePadding>
            {children}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }

  private handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };
}

export default withStyles(styles)(SidebarGroup);
