import { Button } from "@material-ui/core";
import React from "react";
import { ButtonProps } from "@material-ui/core/Button";
import { LinkProps } from "@reach/router";
import { Link } from "gatsby";

export type LinkButtonProps = ButtonProps & LinkProps<{}>;

export const LinkButton: React.SFC<LinkButtonProps> = props => (
  <Button component={Link as any} {...props} />
);
