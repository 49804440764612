import Layout from "./";
import React from "react";
import Blank from "./blank";
import Accounts from "./accounts";
import Loading from "./loading";

interface Props {
  element: {
    page: {
      path: string;
    };
  };
  props: any;
}

const noFooter = /^api|^docs\//;

export default ({ element, props }: Props) => {
  switch (props["uri"]) {
    case "/sign-in":
    case "/register":
      return <Accounts {...props}>{element}</Accounts>;
    case "/get-started":
    case "/first-app":
    case "/first-steps":
      return <Blank {...props}>{element}</Blank>;
    case "/implicit/callback":
      return <Loading {...props}>{element}</Loading>;
    default:
      if (noFooter.test(props["*"])) {
        return (
          <Layout noFooter {...props}>
            {element}
          </Layout>
        );
      }
      return <Layout {...props}>{element}</Layout>;
  }
};
