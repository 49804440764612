import React from "react";
import { AuthHandler, SecurityContext } from "./SecurityContext";

export interface WithAuth {
  auth: AuthHandler;
}

export const withAuth = <P extends WithAuth>(
  Component: React.ComponentType<P>
) => (props: any) => (
  <SecurityContext.Consumer>
    {auth => <Component {...props} auth={auth} />}
  </SecurityContext.Consumer>
);
