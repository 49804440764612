import React from "react";
import Header from "../components/common/header/Header";
import {
  createStyles,
  Fade,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import Sidebar from "../components/common/sidebar/Sidebar";
import { withAuth, WithAuth } from "../components/auth/withAuth";
import withRoot from "../withRoot";
import Footer from "../components/accounts/Footer";
import { colors } from "../Theme";

const styles = (theme: Theme) =>
  createStyles({
    "@global": {
      body: {
        background: colors.background,
        fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
      }
    },
    pageWrapper: {
      display: "flex"
    },
    contentWrapper: {
      position: "relative",
      flexGrow: 1
    },
    content: {
      marginTop: "64px"
    }
  });

interface Props extends WithAuth, WithStyles<typeof styles> {
  noFooter?: boolean;
}

interface State {
  authenticated?: boolean;
  open?: boolean;
}

class Layout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.checkAuthentication();
  }

  toggleDrawer = () => {
    const open = !this.state.open;
    this.setState({ open });
  };

  render() {
    const { noFooter, children, classes } = this.props;

    return (
      <React.Fragment>
        <Fade in timeout={1000}>
          <div className={classes.pageWrapper}>
            <Sidebar authenticated={this.state.authenticated} />
            <div className={classes.contentWrapper}>
              <Header />
              <div className={classes.content}>{children}</div>
              {!noFooter && <Footer />}
            </div>
          </div>
        </Fade>
      </React.Fragment>
    );
  }

  private checkAuthentication = () => {
    this.props.auth
      .isAuthenticated()
      .then(authenticated => {
        if (authenticated !== this.state.authenticated) {
          this.setState({ authenticated });
        }
      })
      .catch(() => this.setState({ authenticated: false }));
  };
}

export default withRoot(withAuth(withStyles(styles)(Layout)));
