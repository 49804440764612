import {
  createStyles,
  NoSsr,
  Tab,
  Tabs,
  Theme,
  Typography,
  WithStyles,
  withStyles
} from "@material-ui/core";
import * as React from "react";
import logoBlack from "../images/certane-logo-black.png";
import NavLink from "gatsby-link";
import withRoot from "../withRoot";
import { Match, Router } from "@reach/router";
import Footer from "../components/accounts/Footer";

const styles = (theme: Theme) =>
  createStyles({
    page: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column" as "column",
      alignItems: "center",
      justifyContent: "start",
      background: "#F4F6F8",
      paddingTop: 100,
      paddingBottom: 50
    },
    card: {
      width: 400,
      textAlign: "center",
      marginBottom: theme.spacing(8)
    },
    continue: {
      margin: theme.spacing(2),
      color: theme.palette.grey[700],

      "& a": {
        color: theme.palette.grey[700],
        textDecoration: "none"
      }
    },
    title: {
      fontSize: 36,
      fontWeight: 100,
      color: theme.palette.grey[900]
    },
    logo: {
      width: 260,
      marginBottom: theme.spacing(2)
    },
    tabs: {
      width: 300,
      marginLeft: "auto",
      marginRight: "auto"
    },
    tab: {
      width: 150,
      fontSize: 14,
      textTransform: "capitalize"
    }
  });

interface Props extends WithStyles<typeof styles> {
  links?: JSX.Element;
  title: string;
}

const FormLayout: React.SFC<Props> = ({ classes, children }) => {
  const RegisterLink = React.forwardRef((props: object, ref: any) => (
    <NavLink to="/register" ref={ref} {...props} />
  ));
  const SignInLink = React.forwardRef((props: object, ref: any) => (
    <NavLink to="/sign-in" ref={ref} {...props} />
  ));

  const SignInText = (props: any) => (
    <Typography variant="subtitle1" className={classes.continue} paragraph>
      <NavLink to="/">Continue to Impact</NavLink>
    </Typography>
  );

  const RegisterText = (props: any) => (
    <Typography variant="subtitle1" className={classes.continue} paragraph>
      or register if you don’t already have one
    </Typography>
  );

  return (
    <NoSsr>
      <div className={classes.page}>
        <div className={classes.card}>
          <img src={logoBlack} className={classes.logo} />

          <Typography variant="h5" className={classes.title} paragraph>
            Sign in to your account
          </Typography>

          <Router primary={false}>
            <SignInText path="/sign-in" />
            <RegisterText path="/register" />
          </Router>

          <Match path="/:tab">
            {props =>
              props.match && (
                <Tabs
                  value={(props.match as any).tab}
                  indicatorColor="primary"
                  textColor="primary"
                  color="primary"
                  className={classes.tabs}
                >
                  <Tab
                    className={classes.tab}
                    value="sign-in"
                    color="primary"
                    label="Sign In"
                    component={SignInLink}
                  />
                  <Tab
                    className={classes.tab}
                    value="register"
                    color="primary"
                    label="Register"
                    component={RegisterLink}
                  />
                </Tabs>
              )
            }
          </Match>

          {children}
        </div>

        <Footer />
      </div>
    </NoSsr>
  );
};

export default withRoot(withStyles(styles)(FormLayout));
