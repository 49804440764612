import React, { ReactNode } from "react";
import { WithAuth, withAuth } from "./withAuth";
import _ from "lodash";

const MAX_IDLE_MS = 10 * 60 * 1000;
const CHECK_INTERVAL_MS = 1000;
const STORAGE_KEY = "lastActionTime";
const ACTIVITY_EVENTS = ["mousedown", "click", "scroll", "keypress"];

const getLastActionTime = () => {
  const item = localStorage.getItem(STORAGE_KEY);
  return item ? parseInt(item, 10) : 0;
};

const setLastActionTime = (time: number) => {
  localStorage.setItem(STORAGE_KEY, `${time}`);
};

const resetLastActionTime = _.throttle(() => {
  setLastActionTime(Date.now());
}, 1000);

interface Props extends WithAuth {}
interface State {}

/**
 * Client side auto logout.
 * We monitor user interactions and store the last time they clicked/scrolled/etc.
 * Then we check on an interval if user is logged in and has been idle > max allowed.
 * Action times are stored in local storage so should work across tabs.
 */
class AutoLogout extends React.Component<Props, State> {
  private timerId: any;

  componentDidMount() {
    resetLastActionTime();
    this.addActivityEventListeners();
    this.startTimer();
  }

  componentWillUnmount(): void {
    this.removeActivityEventListeners();
    this.stopTimer();
  }

  addActivityEventListeners() {
    for (const event of ACTIVITY_EVENTS) {
      window.addEventListener(event, resetLastActionTime);
    }
  }

  removeActivityEventListeners() {
    for (const event of ACTIVITY_EVENTS) {
      window.removeEventListener(event, resetLastActionTime);
    }
  }

  startTimer() {
    this.timerId = setInterval(() => {
      this.checkIfSessionExpired();
    }, CHECK_INTERVAL_MS);
  }

  stopTimer() {
    if (this.timerId) {
      clearInterval(this.timerId);
    }
  }

  checkIfSessionExpired() {
    this.props.auth
      .isAuthenticated()
      .then(isAuthenticated => {
        if (isAuthenticated) {
          const sessionExpiry = getLastActionTime() + MAX_IDLE_MS;
          const sessionRemainingMs = Math.max(0, sessionExpiry - Date.now());

          if (sessionRemainingMs <= 0) {
            console.log("Session expired");
            this.props.auth
              .logout("/sign-in")
              .then(() => console.log("Logged out after session expiry"))
              .catch(() => console.log("Error on auto logout"));
          }
        }
      })
      .catch(() => {
        // Ignore
      });
  }

  render(): ReactNode {
    return null;
  }
}

export default withAuth(AutoLogout);
